@import '../../assets/styles/index.module.scss';

.hero {
  background: linear-gradient(rgba(0,0,0,.33),rgba(0,0,0,.33)), url('../../assets/images/home-hero.jpg');
  background-size: cover;
  background-position: center;
  min-height: 600px;

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include center-section-wrapper;
    @include padding-vertical(60px);
    min-height: 480px;
  }

  &_box {
    background: rgba($primary-color, 0.95);
    max-width: 500px;
    padding: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: $white;
    @include font-open-sans(16px, 24px, semibold);
    @include breakpoint-up(md) {
      padding: 50px;
    }
    @include breakpoint-up(lg) {
      padding: 66px;
    }

    h1 {
      margin-top: 0;
      color: $white;
      @include font-open-sans(32px, 40px, semibold);
      margin-bottom: 15px;
    }

  }

  &_line {
    width: 33%;
    height: 2px;
    background-color: white;
    border: none;
    margin: 10px 0;
  }

  &_button {
    background-color: $secondary-color !important;
    border: $secondary-color !important;
    padding: 8px 40px;
    margin-top: 30px;
  }
}


.family {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 480px;
  text-align: center;
  @include center-section-wrapper;
  @include padding-vertical(60px);
  overflow: hidden;

  @include breakpoint-up(lg) {
    flex-direction: row;
    text-align: left;
  }

  h2 {
    @include font-open-sans(32px, 40px, semibold);
    margin-top: 0px;
  }

  &_focus {
    @include font-open-sans(14px, 20px, semibold);
    color: $primary-color;
  }

  &_column {
    max-width: 500px;
    padding: 20px;
  }
  &_image {
    max-width: 500px;
  }
}

.leistungen {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &_bg {
    background-color: $primary-color;
    @include padding-vertical(60px);
    text-align: center;

    h2 {
      @include font-open-sans(32px, 40px, semibold);
      color: $white;
    }

    p {
      color: $white;
      max-width: 666px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  @include center-section-wrapper;
  @include padding-vertical(60px);

  &_box {
    max-width: 300px;
    min-height: 280px;
    background-color: white;
    margin: 20px auto; 

    img, p {
      max-width: 300px;
    }
    & p {
      text-align: center;
      @include font-open-sans(16px, 24px, semibold);
      color: $black;
      padding: 20px 0;
      margin-top: -6px;
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary-color;
    }
    &:hover {
      box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.1);
    }
  }

  &_link {
    text-decoration: none;
    padding: 0 10px;
  }

}


.karriere {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 480px;
  text-align: center;
  @include center-section-wrapper;
  @include padding-vertical(60px);
  overflow: hidden;

  @include breakpoint-up(lg) {
    flex-direction: row;
    text-align: left;
  }

  h2 {
    @include font-open-sans(32px, 40px, semibold);
    margin-top: 0px;
    margin-bottom: 15px;
    color: $primary-color;
  }

  h3 {
    color: white;
    margin-top: 0px;
    @include font-open-sans(22px, 30px, semibold);
  }

  &_text {
    @include font-open-sans(16px, 24px, semibold);
  }

  &_button {
    margin-top: 30px;
  }

  &_column {
    max-width: 450px;
    padding: 20px 0;

    &:last-of-type {
      background-color: $secondary-color;
      margin: 20px 0;
      padding: 40px;
      color: white;
      max-width: 333px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @include breakpoint-up(md) {
        padding: 50px;
      }
    }
  }
}