@import '../../assets/styles/index.module.scss';

.hero {
  background: linear-gradient(rgba(0,0,0,.33),rgba(0,0,0,.33)), url('../../assets/images/leistungen-hero.jpg');
  background-size: cover;
  background-position: center;
  @include padding-vertical(60px);
  display: flex;
  min-height: 480px;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 0px;
    color: $white;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.wrapper {
  @include center-section-wrapper;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.box {
  background: rgba($primary-color, 0.95);
  padding: 50px;
  color: $white;
  max-width: 360px;
  float: right;
}

.family {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 333px;
  text-align: left;
  @include center-section-wrapper;
  @include padding-vertical(60px, 0px);
  overflow: hidden;

  &:last-of-type {
    @include padding-vertical(60px);
  }

  @include breakpoint-up(lg) {
    @include padding-vertical(60px);
    flex-direction: row;
    text-align: left;
  }

  &_reverse {
    @extend .family;
    flex-direction: column-reverse;
    @include breakpoint-up(lg) {
      flex-direction: row;
      text-align: left;
    }
  }

  h2 {
    @include font-open-sans(32px, 40px, semibold);
    color: $secondary-color;
    margin-top: 0px;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    @include breakpoint-up(md) {
      padding-left: 20px;
    }
  }

  &_column {
    max-width: 500px;
    padding: 20px 0;
    @include breakpoint-up(md) {
      padding: 20px;
    }
  }
  &_image {
    max-width: 500px;
  }
}

.small {
  @include font-open-sans(12px, 20px, regular);
}

.icon {
  color: $primary-color;
  padding-right: 10px;
}

.pruefV {
  @extend .family;

  & p:last-of-type {
    margin-bottom: 40px;
  }

  &_column {
    max-width: 500px;
    padding: 20px 0;
    @include breakpoint-up(md) {
      padding: 20px;
    }
  }
  &_image {
    max-width: 500px;
  }

}