@import '../../assets/styles/index.module.scss';

.background {
  background: linear-gradient(176.36deg, $secondary-color 0%, rgba(255, 255, 255, 0.97) 67.61%);
}

.header {
  text-align: center;

  &_h1 {
    margin-bottom: 0px;
    color: white;
    @include font-open-sans(36px, 44px, semibold);
  }

  &_text {
    @include font-open-sans(16px, 24px, regular);
    color: white;
  }

  &_wrapper {
    @include center-section-wrapper;
    @include padding-vertical(60px, 30px)
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include center-section-wrapper;
  @include padding-vertical(30px, 120px);
  text-align: center;
  @include breakpoint-up(xl) {
    flex-direction: row;
    text-align: left;
  }
}

.flex {
  width: 100%;
  padding-bottom: 60px;
  @include breakpoint-up(xl) {
    width: 50%;
    padding-bottom: 120px;
  }

  &>h2 {
    font-size: 24px;
    margin-top: 0px;
  }

  &:first-of-type {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 400px;
    height: fit-content;
    margin: 20px auto 0;
    padding: 30px;
    background: $white;
    border: 1px solid $divider-color;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
    @include breakpoint-up(md) {
      padding: 40px;
      max-width: 420px;
    }

    @include breakpoint-up(xl) {
      padding: 50px;
    }
  }
}

.prefix:first-of-type {
  padding-right: 10px;
}

.prefix:last-of-type {
  padding-right: 20px;
}