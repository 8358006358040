@import '../../assets/styles/index.module.scss';

.background {
  border-bottom: 1px solid $divider-color;
  background: white;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.1);
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  @include center-section-wrapper;
}

.logo {
  text-decoration: none;
  height: fit-content;
}

.navLink {

  @include font-open-sans(16px, 24px, semibold);
  @include padding-horizontal(20px);
  text-decoration: none;

  &:last-of-type {
    padding-right: 0px;
  }

  &:visited {
    text-decoration: none;
    color: $secondary-color;
  }
  
  &:hover {
    color: $primary-color;
  }

  &_active {
    color: $primary-color;
  }

}

.button {
  background-color: $secondary-color !important;
  border: 1px solid $secondary-color !important;
}

.menuBars{

  @include size(66px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -20px;

  &_icon {
    font-size: 30px;
    line-height: 36px;
    color: $icon-color;
  }
}