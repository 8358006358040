@import './assets/styles/index.module.scss';

html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;

  @include font-open-sans(16px, 24px, regular);
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint-up(lg) {
    @include font-open-sans(14px, 22px, regular)
  }
}

h1 {
  @include font-open-sans(30px, 40px, semibold);
  color: $heading-color;
}

h2 {
  @include font-open-sans(20px, 28px, semibold);
  color: $heading-color;
}

h3 {
  @include font-open-sans(16px, 24px, semibold);
  color: $heading-color;
}
