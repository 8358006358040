@import '../../assets/styles/index.module.scss';

.background {
  height: calc(100vh - 66px);
  padding-top: 10%;
  overflow: scroll;
  ::-webkit-scrollbar { 
    display: none; 
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 500px;
  ::-webkit-scrollbar { 
    display: none; 
  }
}

.navLink {
  width: 100%;
  padding: 15px 0;

  color: $primary-color;
  @include font-open-sans(24px, 32px, regular);
  text-decoration: none;
  text-align: center;

  &:nth-of-type(2) {
    padding: 45px 0 15px;
  }
}