// Primary Brand Colors

$primary-color: #FCC707;
$primary-hover-color: #4C84FF;

$secondary-color: #0A3079;
$secondary-hover-color: #4C84FF;

$tertiary-color: #FFC005;
$tertiary-hover-color: #4C84FF;

// Typography Colors

$heading-color: rgba(0,0,0,0.85);
$text-color: rgba(0,0,0,0.65);
$icon-color: #707683;
$placeholder-color: rgba(112, 118, 131, 0.55);

// Border, Background, etc. 

$background-color: #ffffff;
$background-hover-color: #3FB048;
$border-color: #90A0B7;
$divider-color: #ededed;

// Signal Colors

$green-color: #3FB048;
$red-color: #FF0F19;

// White & Black

$white: #ffffff;
$black: #000000;






