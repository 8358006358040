@import '../../assets/styles/index.module.scss';

.hero {
  background: linear-gradient(rgba(0,0,0,.33),rgba(0,0,0,.33)), url('../../assets/images/pruefV-hero.jpg');
  background-size: cover;
  background-position: center;
  @include padding-vertical(60px);
  display: flex;
  min-height: 480px;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 0px;
    color: $white;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: $max-page-width;
  width: 100%;
}

.box {
  background: rgba($primary-color, 0.95);
  padding: 40px;
  margin: 0 20px;
  color: $white;
  max-width: 400px;
  float: right;
  @include breakpoint-up(md) {
    padding: 50px;
    margin: 0px;
  }
}

.description {
  @include center-section-wrapper;
  @include padding-vertical(60px);

  &_inner {
    width: 100%;

    h2 {
      @include font-open-sans(32px, 40px, semibold);
      color: $secondary-color;
      max-width: 666px;
    }

    p {
      max-width: 666px;
    }

  }
}

.pictures {
  @include center-section-wrapper;
  @include padding-vertical(60px);
  background-color: $primary-color;

  &_inner {
    display: flex;
    overflow: hidden;
    justify-content: center;
  }

  &_img {
    height: 300px;
  }
  &_img2 {
    display: none;
    @include breakpoint-up(md) {
      display: inline;
      height: 300px;
      margin: 0 30px;
    }
  }
  &_img3 {
    display: none;
    transform: rotate(90deg);
    @include breakpoint-up(xl) {
      display: inline;
      height: 300px;
      overflow: hidden;
    }
  }

}

.contact {
  @include center-section-wrapper;
  @include padding-vertical(60px);
  text-align: center;
  min-height: 300px;

  h2 {
    @include font-open-sans(32px, 40px, semibold);
    color: $secondary-color;
    @include breakpoint-up(md) {
      padding-top: 40px;
    }
  }

  p {
    margin-bottom: 30px;
  }
}