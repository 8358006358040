@import '../../assets/styles/index.module.scss';

.job {
  @include center-section-wrapper;
  @include padding-vertical(60px);

  h2 {
    font-size: 32px;
    line-height: 40px;
    color: $secondary-color;
  }
}

.hero {
  background: linear-gradient(rgba(0,0,0,.33),rgba(0,0,0,.33)), url('../../assets/images/karriere.jpg');
  background-size: cover;
  background-position: center;
  @include padding-vertical(60px);
  display: flex;
  min-height: 480px;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 0px;
    color: $white;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.wrapper {
  @include center-section-wrapper;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.box {
  background: rgba($primary-color, 0.95);
  padding: 50px;
  color: $white;
  width: fit-content;
}

.padding {
  height: 10px;
}

.details {
  & span:last-of-type {
    font-weight: 600;
    color: $secondary-color;
  }
}

.interested {
  font-weight: bold;
  color: $secondary-color;
}